var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("el-row", [
        _c("span", { staticClass: "back-tit" }, [_vm._v("题库详情")]),
      ]),
      _c(
        "el-breadcrumb",
        { staticClass: "breadcrumb row-bg", attrs: { separator: "/" } },
        [
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { path: "/senate/class-quesbank" } } },
            [_vm._v("班级题库列表")]
          ),
          _c("el-breadcrumb-item", {
            domProps: { textContent: _vm._s(_vm.classData.contestName) },
          }),
        ],
        1
      ),
      _c("el-row", { staticClass: "class-detail row-bg" }, [
        _c("i", { staticClass: "el-icon-s-custom" }),
        _c("span", {
          domProps: { textContent: _vm._s(_vm.classData.className) },
        }),
        _c("i", { staticClass: "el-icon-time" }),
        _c("span", { domProps: { textContent: _vm._s(_vm.classTime) } }, [
          _vm._v("上课时间："),
        ]),
        _c("i", { staticClass: "el-icon-user" }),
        _c(
          "span",
          [
            _vm._v("学员人数："),
            _c("font", { domProps: { textContent: _vm._s(_vm.classNum) } }),
          ],
          1
        ),
      ]),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "题库问题", name: "first" } },
            [_c("library-list", { attrs: { classData: _vm.classData } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "提交记录", name: "second" } },
            [
              _c("sub-record", {
                attrs: { classData: _vm.classData, isBack: false },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "提交统计", name: "third" } },
            [_c("sub-rank", { attrs: { classData: _vm.classData } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }