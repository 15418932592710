<template>
  <el-row>
    <el-button style="margin-bottom: 15px;" @click="getSubmitHistory()" type="warning">刷新统计</el-button>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="学号" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.loginName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="解决" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.compleNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="罚时" align="center">
        <template slot-scope="scope">
          <span v-show="scope.row.total_cost != 0">{{ formatDuring(scope.row.total_cost) }}</span>
          <span v-show="scope.row.total_cost == 0">0</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="item+''"
        v-for="(item, index) of problems"
        :key="index"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="problems[index].problemName"
            placement="top"
          >
            <span>{{problems[index].problem_id}}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <span
            :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
            v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 0"
          >错{{scope.row.everyProblem[index].errroNum}}次</span>
          <span
            :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
            v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1"
          >{{formatDuring(scope.row.everyProblem[index].time_cost)}}</span><span v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1" style="color: #CE433F;">(错{{scope.row.everyProblem[index].errroNum}}次)</span>
          <span
            :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
            v-show="scope.row.everyProblem[index].errroNum == 0 && scope.row.everyProblem[index].rightFlag == 1"
          >{{formatDuring(scope.row.everyProblem[index].time_cost)}}</span>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
</template>

<script>
import { getStudentSolutionStatistics } from '@/api/research/quesBank'
export default {
  props: {
    classData: {
      default: () => {
        return {};
      },
      type: Object
    }
  },
  data() {
    return {
      dataList: [],
      problems: []
    };
  },
  created() {
    this.getSubmitHistory();
  },
  methods: {
    formatDuring(mss) {
      let hours = Math.floor((mss/60/60));
      let minutes = Math.floor((mss % (60 * 60)) / 60);
      let seconds = Math.round(mss % 60);
      if (hours != 0) {
        return (
          this.Appendzero(hours) +
          this.Appendzero(minutes) +
          this.SecondAppendzero(seconds)
        );
      } else {
        if (minutes == 0) {
          return (
            this.Appendzero(0) +
            this.Appendzero(0) +
            this.SecondAppendzero(seconds)
          );
        } else if (hours == 0) {
          return (
            this.Appendzero(0) +
            this.Appendzero(minutes) +
            this.SecondAppendzero(seconds)
          );
        }
      }
    },
    Appendzero(obj) {
      if (obj < 10) return "0" + "" + obj + ":";
      else return obj + ":";
    },
    SecondAppendzero(obj) {
      if (obj < 10) return "0" + "" + obj;
      else return obj;
    },
    // 获取学生统计
    async getSubmitHistory() {
      const res = await getStudentSolutionStatistics({
        contest_id: this.classData.contest_id,
        lesson_id: this.classData.lesson_id
      })
      this.dataList = res.body.tbody;
      this.problems = res.body.problems;
    },

    handleCurrentChange(val) {},
    handleSizeChange(val) {},
    getStateColor(state) {
      switch (state) {
        case 0:
          return "#CE433F";
          break;
        case 1:
          return "#0CA51A";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
</style>
