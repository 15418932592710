<template>
  <el-row class="table">
    <el-row>
      <span class="back-tit">题库详情</span>
    </el-row>
    <el-breadcrumb separator="/" class="breadcrumb row-bg">
      <el-breadcrumb-item :to="{ path: '/senate/class-quesbank' }">班级题库列表</el-breadcrumb-item>
      <el-breadcrumb-item v-text="classData.contestName"></el-breadcrumb-item>
    </el-breadcrumb>
    <el-row class="class-detail row-bg">
      <i class="el-icon-s-custom"></i>
      <span v-text="classData.className"></span>
      <i class="el-icon-time"></i>
      <span v-text="classTime">上课时间：</span>
      <i class="el-icon-user"></i>
      <span>学员人数：<font v-text="classNum"></font></span>
    </el-row>
    <!-- tabs -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="题库问题" name="first">
        <library-list :classData="classData"></library-list>
      </el-tab-pane>
      <el-tab-pane label="提交记录" name="second">
        <sub-record :classData="classData" :isBack="false"></sub-record>
      </el-tab-pane>
      <el-tab-pane label="提交统计" name="third">
        <sub-rank :classData="classData"></sub-rank>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import libraryList from '@/components/senate/library-list/'
import subRecord from '@/components/senate/sub-record/'
import subRank from '@/components/senate/sub-rank/'

import { getLessonInfo } from '@/api/senate/lesson'

export default {
  name: "workListDetail",
  components: {
    libraryList,
    subRecord,
    subRank
  },
  data() {
    return {
      activeName: this.$route.query.activeName || "first",
      classTime: "",
      classNum: "",
      stuList: [],
      switchAll: false,
      classData: {},
    };
  },
  created() {
    this.classData = eval("(" + this.$route.query.classData + ")");
    this.getClassInfo();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    // 获取班级信息
    async getClassInfo() {
      const res = await getLessonInfo({ lesson_id: this.classData.lesson_id })
      this.classTime = res.body[0].start_time
      this.classNum = res.body[0].studentNum
    },

    getState(state) {
      switch (state) {
        case true:
          return "开放(all)";
          break;
        case false:
          return "关闭";
          break;
        default:
          return `开放(${state})`;
      }
    },
    getStateColor(state) {
      switch (state) {
        case true:
          return "#0CA51A";
          break;
        case false:
          return "#CE433F";
          break;
        default:
          return "#f5c319";
      }
    },

    // 全选
    switchCheckAll() {
      let state = this.switchAll;
      if (state === false) {
        this.stuList.map((item, index, arr) => {
          return (item.state = 0);
        });
      } else {
        this.stuList.map((item, index, arr) => {
          return (item.state = 1);
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
.stuList {
  display: flex;
  flex-direction: column;

  li {
    flex: 1;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .stu-tit {
    display: inline-block;
    padding-left: 10px;
    justify-content: flex-start;
  }

  .stu-switch {
    float: right;
  }
}

/deep/ .el-tabs__item {
  font-size: 16px;
  padding: 0 30px;
}
</style>
