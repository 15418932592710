var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { type: "warning" },
          on: {
            click: function ($event) {
              return _vm.getSubmitHistory()
            },
          },
        },
        [_vm._v("刷新统计")]
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "学号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.loginName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "解决", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.compleNum))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "罚时", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.total_cost != 0,
                            expression: "scope.row.total_cost != 0",
                          },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.formatDuring(scope.row.total_cost)))]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.total_cost == 0,
                            expression: "scope.row.total_cost == 0",
                          },
                        ],
                      },
                      [_vm._v("0")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.problems, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: { label: item + "", align: "center" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.problems[index].problemName,
                              placement: "top",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.problems[index].problem_id)),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.everyProblem[index].errroNum != 0 &&
                                  scope.row.everyProblem[index].rightFlag == 0,
                                expression:
                                  "scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 0",
                              },
                            ],
                            style:
                              "color" +
                              ":" +
                              _vm.getStateColor(
                                scope.row.everyProblem[index].rightFlag
                              ),
                          },
                          [
                            _vm._v(
                              "错" +
                                _vm._s(scope.row.everyProblem[index].errroNum) +
                                "次"
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.everyProblem[index].errroNum != 0 &&
                                  scope.row.everyProblem[index].rightFlag == 1,
                                expression:
                                  "scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1",
                              },
                            ],
                            style:
                              "color" +
                              ":" +
                              _vm.getStateColor(
                                scope.row.everyProblem[index].rightFlag
                              ),
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatDuring(
                                  scope.row.everyProblem[index].time_cost
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.everyProblem[index].errroNum != 0 &&
                                  scope.row.everyProblem[index].rightFlag == 1,
                                expression:
                                  "scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1",
                              },
                            ],
                            staticStyle: { color: "#CE433F" },
                          },
                          [
                            _vm._v(
                              "(错" +
                                _vm._s(scope.row.everyProblem[index].errroNum) +
                                "次)"
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.everyProblem[index].errroNum == 0 &&
                                  scope.row.everyProblem[index].rightFlag == 1,
                                expression:
                                  "scope.row.everyProblem[index].errroNum == 0 && scope.row.everyProblem[index].rightFlag == 1",
                              },
                            ],
                            style:
                              "color" +
                              ":" +
                              _vm.getStateColor(
                                scope.row.everyProblem[index].rightFlag
                              ),
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatDuring(
                                  scope.row.everyProblem[index].time_cost
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }