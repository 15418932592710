<template>
  <el-row class="table">
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
      style="margin-bottom:30px;"
      ref="multipleTableParent"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="题号" align="center">
        <template slot-scope="scope">
          <span class="tb-span">{{ scope.row.problem_id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="问题" align="center">
        <template slot-scope="scope">
          <span
            @click="workPractice(scope.row)"
            class="title-link"
          >{{ scope.row.problemName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span
            v-if="scope.row.check_num === scope.row.check_total_num"
            style="color: #0CA51A"
          >开放(all)</span>
          <span
            v-else
            v-text="getState(scope.row.check_num)"
            no
            promises
            :style="'color'+':'+getStateColor(scope.row.check_num)"
          ></span>
        </template>
      </el-table-column>
      <el-table-column label="提交记录" align="center">
        <template slot-scope="scope">
          <span
            style="cursor: pointer;"
            @click="subRecord(scope.row)"
          >{{scope.row.right_num}}/{{scope.row.total_num}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="230">
        <template slot-scope="scope">
          <el-popover placement="right" width="238" @hide="hideMethod" trigger="click">
            <ul class="stuList clearfix">
              <li>
                <span class="stu-tit">全体</span>
                <el-switch
                  class="stu-switch"
                  v-model="switchAll"
                  @change="switchCheckAll(scope.row, scope.$index)"
                ></el-switch>
              </li>
              <li v-for="(item, index) of scope.row.lessionStudentInfos" :key="index">
                <span class="stu-tit">{{item.loginName}}{{item.name}}</span>
                <el-switch
                  class="stu-switch"
                  v-model="item.is_check"
                  :active-value="1"
                  :inactive-value="2"
                  @change="switchCheck(scope.row, item.userId, item.is_check,index)"
                ></el-switch>
              </li>
            </ul>
            <el-button
              slot="reference"
              size="small"
              type="primary"
              @click="openStudent(scope.$index)"
              plain
              style="margin-left: 10px;"
            >开放学员</el-button>
          </el-popover>
          <el-popover placement="right" width="238" @hide="hideMethod" trigger="click">
            <ul class="stuList clearfix">
              <li>
                <span class="stu-tit">全体</span>
                <el-switch
                  class="stu-switch"
                  v-model="switchVideoAll"
                  @change="switchCheckVideoAll(scope.row, scope.$index)"
                ></el-switch>
              </li>
              <li v-for="(item, index) of scope.row.lessionStudentInfos" :key="index">
                <span class="stu-tit">{{item.loginName}}{{item.name}}</span>
                <el-switch
                  class="stu-switch"
                  v-model="item.defunctVideo"
                  :active-value="'N'"
                  :inactive-value="'Y'"
                  @change="switchVideo(scope.row, item.userId, item.defunctVideo,index)"
                ></el-switch>
              </li>
            </ul>
            <el-button
              v-if="scope.row.video"
              slot="reference"
              size="small"
              type="primary"
              @click="openStudentVideo(scope.$index)"
              plain
              style="margin-left: 10px;"
            >题解视频</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <el-popover ref="popover4" placement="right" width="400" v-model="visible2" trigger="click">
      <el-table
        ref="multipleTable"
        :data="studentMessageArray"
        tooltip-effect="dark"
        style="width: 100%;height: 350px;overflow-y: auto"
        @selection-change="handleSelection"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="loginName" label="学号" width="120"></el-table-column>
        <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-button style="margin-top: 10px;" @click="openSomeStuSomeQues">全部开放</el-button>
      <el-button style="margin-top: 10px;" @click="closeSomeStuSomeQues">全部关闭</el-button>

      <el-button slot="reference" type="info" size="medium" style="margin-top: 20px;">批量开放</el-button>
    </el-popover>
  </el-row>
</template>

<script>
import { getQuestionList, updateQuestionState,updateProblemUser } from '@/api/research/quesBank'

export default {
  props: {
    classData: {
      default: () => {
        return {};
      },
      type: Object
    }
  },
  data() {
    return {
      dataList: [],
      stuList: [],
      batchStuList: [],
      switchAll: false,
      switchVideoAll: false,
      batchSwitchAll: false,
      clickIndex: 0,
      clickVideoIndex:0,
      visible2: false,
      getFirstStudentArray: false,
      studentMessageArray: [],
      userIdArray: [],
      questionArray: [],
      titleIndex: []
    };
  },
  created() {
    this.getQuestionList();
  },
  methods: {
    subRecord(row) {
      if (row.total_num == 0) {
        $msg("暂无提交记录", 2);
      } else {
        this.$router.push({
          path: "/senate/record-list/",
          query: {
            class_id: this.classData.class_id,
            contest_id: this.classData.contest_id,
            lesson_id: this.classData.lesson_id,
            problem_id: row.problem_id,
            role: "libraryList"
          }
        });
      }
    },
    hideMethod() {
      this.getQuestionList();
    },
    openStudentVideo(index) {
      this.clickVideoIndex = index;
    },
    // 获取题库集
    async getQuestionList() {
      const res = await getQuestionList({
        contest_id: this.classData.contest_id,
        lesson_id: this.classData.lesson_id
      })
      this.dataList = res.body
      if (!this.getFirstStudentArray) {
        this.getFirstStudentArray = true;
        this.studentMessageArray = this.dataList[0].lessionStudentInfos;
      }
    },

    handleCurrentChange(val) {},
    handleSizeChange(val) {},

    getState(state) {
      switch (state) {
        case 0:
          return "关闭";
          break;
        default:
          return `开放(${state})`;
      }
    },
    getStateColor(state) {
      switch (state) {
        case 0:
          return "#CE433F";
          break;
        default:
          return "#0CA51A";
      }
    },

    // 单个开放学员
    switchCheck(row, userId, state, index) {
      if (state === 2) {
        updateQuestionState([
          {
            user_id: userId,
            lesson_id: this.classData.lesson_id,
            contest_id: Number(this.classData.contest_id),
            problem_id: row.problem_id,
            status: "已删除"
          }
        ]).then(res => {
          if (res.state === 'success') {
            window.$msg("操作成功");
            var thembArray = this.dataList[this.clickIndex]
              .lessionStudentInfos;
            for (let i = 0; i < thembArray.length; i++) {
              if (thembArray[i].user_id == userId) {
                this.dataList[index].lessionStudentInfos[i].is_check = 1;
              }
            }
          }
        })
      } else {
        updateQuestionState([
          {
            user_id: userId,
            lesson_id: this.classData.lesson_id,
            contest_id: Number(this.classData.contest_id),
            problem_id: row.problem_id,
            status: "已启用"
          }
        ]).then(res => {
          if (res.state === 'success') {
            $msg("操作成功");
            // this.getQuestionList()
            var thembArray = this.dataList[this.clickIndex]
              .lessionStudentInfos;
            for (let i = 0; i < thembArray.length; i++) {
              if (thembArray[i].user_id == userId) {
                this.dataList[index].lessionStudentInfos[i].is_check = 1;
              }
            }
          }
        })
      }
    },

    // 单个开放学员题解视频
    switchVideo(row, userId,defunctVideo,index) {
      updateProblemUser([
        {
          userId: userId,
          problemId: row.problem_id,
          contestId:Number(this.classData.contest_id),
          defunctVideo: defunctVideo === "N" ? 'N':'Y'
        }
      ]).then(res => {
        if (res.state === 'success') {
          window.$msg("操作成功");
          var thembArray = this.dataList[this.clickVideoIndex]
            .lessionStudentInfos;
          for (let i = 0; i < thembArray.length; i++) {
            if (thembArray[i].user_id == userId) {
              this.dataList[index].lessionStudentInfos[i].defunctVideo = (defunctVideo === "N" ? 'N':'Y');
            }
          }
        }
      })
    },

    // 题解视频全选
    switchCheckVideoAll(row, index) {
      this.stuList = row.lessionStudentInfos;
      if (this.switchVideoAll === false) {
        let switchArr = [];
        this.stuList.map((item, index) => {
          if (item.defunctVideo === 'N') {
            switchArr.push(
              Object.assign(
                {},
                {
                  userId: item.userId,
                  problemId: row.problem_id,
                  contestId:Number(this.classData.contest_id),
                  defunctVideo: "Y"
                }
              )
            );
          }
        });

        updateProblemUser(JSON.stringify(switchArr))
          .then(res => {
            if (res.state === 'success') {
              $msg("操作成功");
              // this.getQuestionList()
              var thembArray = this.dataList[this.clickVideoIndex]
                .lessionStudentInfos;
              for (let i = 0; i < thembArray.length; i++) {
                this.dataList[index].lessionStudentInfos[i].defunctVideo = "Y";
              }
            }
          })
      } else {
        let switchArr = [];
        this.stuList.map((item, index) => {
          if (item.defunctVideo === 'Y') {
            switchArr.push(
              Object.assign(
                {},
                {
                  userId: item.userId,
                  problemId: row.problem_id,
                  contestId:Number(this.classData.contest_id),
                  defunctVideo: "N"
                }
              )
            );
          }
        });
        updateProblemUser(JSON.stringify(switchArr))
          .then(res => {
            if (res.state === 'success') {
              $msg("操作成功");
              var thembArray = this.dataList[this.clickVideoIndex]
                .lessionStudentInfos;
              for (let i = 0; i < thembArray.length; i++) {
                this.dataList[index].lessionStudentInfos[i].defunctVideo = 'N';
              }
            }
          })
      }
    },

    // 全选
    switchCheckAll(row, index) {
      this.stuList = row.lessionStudentInfos;
      if (this.switchAll === false) {
        let switchArr = [];
        this.stuList.map((item, index) => {
          if (item.is_check === 1) {
            switchArr.push(
              Object.assign(
                {},
                {
                  user_id: item.userId,
                  lesson_id: this.classData.lesson_id,
                  contest_id: Number(this.classData.contest_id),
                  problem_id: row.problem_id,
                  status: "已删除"
                }
              )
            );
          }
        });

        updateQuestionState(JSON.stringify(switchArr))
          .then(res => {
            if (res.state === 'success') {
              $msg("操作成功");
              // this.getQuestionList()
              var thembArray = this.dataList[this.clickIndex]
                .lessionStudentInfos;
              for (let i = 0; i < thembArray.length; i++) {
                this.dataList[index].lessionStudentInfos[i].is_check = 2;
              }
            }
          })
      } else {
        let switchArr = [];
        this.stuList.map((item, index) => {
          if (item.is_check === 2) {
            switchArr.push(
              Object.assign(
                {},
                {
                  user_id: item.userId,
                  lesson_id: this.classData.lesson_id,
                  contest_id: Number(this.classData.contest_id),
                  problem_id: row.problem_id,
                  status: "已启用"
                }
              )
            );
          }
        });
        updateQuestionState(JSON.stringify(switchArr))
          .then(res => {
            if (res.state === 'success') {
              $msg("操作成功");
              // this.getQuestionList()
              var thembArray = this.dataList[this.clickIndex]
                .lessionStudentInfos;
              for (let i = 0; i < thembArray.length; i++) {
                this.dataList[index].lessionStudentInfos[i].is_check = 1;
              }
            }
          })
      }
    },
    handleSelection(sels) {
      this.userIdArray = sels;
    },
    handleSelectionChange(sels) {
      this.questionArray = sels;
    },

    openSomeStuSomeQues() {
      if (this.userIdArray.length == 0) {
        $msg("请先选择需要开放问题的学员");
        return false;
      } else if (this.questionArray.length == 0) {
        $msg("请先选择需要开放的问题");
        return false;
      }

      var thembArray = [];
      for (let i = 0; i < this.questionArray.length; i++) {
        for (let j = 0; j < this.userIdArray.length; j++) {
          var jsonObject = new Object();

          jsonObject.lesson_id = this.classData.lesson_id;
          jsonObject.contest_id = Number(this.classData.contest_id);
          jsonObject.user_id = this.userIdArray[j].userId;
          jsonObject.problem_id = this.questionArray[i].problem_id;
          jsonObject.status = "已启用";
          thembArray.push(jsonObject);
        }
      }

      var storeIndex = [];

      for (let i = 0; i < this.questionArray.length; i++) {
        for (let j = 0; j < thembArray.length; j++) {
          for (
            let k = 0;
            k < this.questionArray[i].lessionStudentInfos.length;
            k++
          ) {
            if (
              thembArray[j].problem_id == this.questionArray[i].problem_id &&
              thembArray[j].user_id ==
                this.questionArray[i].lessionStudentInfos[k].userId &&
              this.questionArray[i].lessionStudentInfos[k].is_check == 1
            ) {
              storeIndex.push(j);
            }
          }
        }
      }
      var thembArrayReal = [];
      for (let i = 0; i < thembArray.length; i++) {
        if (storeIndex.indexOf(i) == -1) {
          thembArrayReal.push(thembArray[i]);
        }
      }

      updateQuestionState(thembArrayReal).then(res => {
        if (res.state === 'success') {
          window.$msg("操作成功");
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTableParent.clearSelection();
          this.userIdArray = [];
          this.questionArray = [];
          this.visible2 = false;
          this.getQuestionList();
        }
      })
    },

    closeSomeStuSomeQues() {
      if (this.userIdArray.length == 0) {
        $msg("请先选择需要关闭问题的学员");
        return false;
      } else if (this.questionArray.length == 0) {
        $msg("请先选择需要关闭的问题");
        return false;
      }

      var thembArray = [];
      for (let i = 0; i < this.questionArray.length; i++) {
        for (let j = 0; j < this.userIdArray.length; j++) {
          var jsonObject = new Object();

          jsonObject.lesson_id = this.classData.lesson_id;
          jsonObject.contest_id = Number(this.classData.contest_id);
          jsonObject.user_id = this.userIdArray[j].userId;
          jsonObject.problem_id = this.questionArray[i].problem_id;
          jsonObject.status = "已删除";
          thembArray.push(jsonObject);
        }
      }

      var storeIndex = [];

      for (let i = 0; i < this.questionArray.length; i++) {
        for (let j = 0; j < thembArray.length; j++) {
          for (
            let k = 0;
            k < this.questionArray[i].lessionStudentInfos.length;
            k++
          ) {
            if (
              thembArray[j].problem_id == this.questionArray[i].problem_id &&
              thembArray[j].user_id ==
                this.questionArray[i].lessionStudentInfos[k].userId &&
              this.questionArray[i].lessionStudentInfos[k].is_check == 2
            ) {
              storeIndex.push(j);
            }
          }
        }
      }
      var thembArrayReal = [];
      for (let i = 0; i < thembArray.length; i++) {
        if (storeIndex.indexOf(i) == -1) {
          thembArrayReal.push(thembArray[i]);
        }
      }
      updateQuestionState(thembArrayReal).then(res => {
        if (res.state === 'success') {
          window.$msg("操作成功");
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTableParent.clearSelection();
          this.userIdArray = [];
          this.questionArray = [];
          this.visible2 = false;
          this.getQuestionList();
        }
      })
    },

    workPractice(row) {
      let routeData = this.$router.resolve({
        path: "/research/question-details",
        query: {
          problemID: row.problem_id,
        }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
.stuList {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
  li {
    margin-top: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .stu-tit {
    display: inline-block;
    padding-left: 10px;
    justify-content: flex-start;
  }

  .stu-switch {
    float: right;
  }
}
</style>
