var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-table",
        {
          ref: "multipleTableParent",
          staticClass: "tb-list",
          staticStyle: { "margin-bottom": "30px" },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "题号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "tb-span" }, [
                      _vm._v(_vm._s(scope.row.problem_id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "问题", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "title-link",
                        on: {
                          click: function ($event) {
                            return _vm.workPractice(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.problemName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.check_num === scope.row.check_total_num
                      ? _c("span", { staticStyle: { color: "#0CA51A" } }, [
                          _vm._v("开放(all)"),
                        ])
                      : _c("span", {
                          style:
                            "color" +
                            ":" +
                            _vm.getStateColor(scope.row.check_num),
                          attrs: { no: "", promises: "" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.getState(scope.row.check_num)
                            ),
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "提交记录", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.subRecord(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.right_num) +
                            "/" +
                            _vm._s(scope.row.total_num)
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          width: "238",
                          trigger: "click",
                        },
                        on: { hide: _vm.hideMethod },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "stuList clearfix" },
                          [
                            _c(
                              "li",
                              [
                                _c("span", { staticClass: "stu-tit" }, [
                                  _vm._v("全体"),
                                ]),
                                _c("el-switch", {
                                  staticClass: "stu-switch",
                                  on: {
                                    change: function ($event) {
                                      return _vm.switchCheckAll(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.switchAll,
                                    callback: function ($$v) {
                                      _vm.switchAll = $$v
                                    },
                                    expression: "switchAll",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._l(
                              scope.row.lessionStudentInfos,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index },
                                  [
                                    _c("span", { staticClass: "stu-tit" }, [
                                      _vm._v(
                                        _vm._s(item.loginName) +
                                          _vm._s(item.name)
                                      ),
                                    ]),
                                    _c("el-switch", {
                                      staticClass: "stu-switch",
                                      attrs: {
                                        "active-value": 1,
                                        "inactive-value": 2,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.switchCheck(
                                            scope.row,
                                            item.userId,
                                            item.is_check,
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.is_check,
                                        callback: function ($$v) {
                                          _vm.$set(item, "is_check", $$v)
                                        },
                                        expression: "item.is_check",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              slot: "reference",
                              size: "small",
                              type: "primary",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openStudent(scope.$index)
                              },
                            },
                            slot: "reference",
                          },
                          [_vm._v("开放学员")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          width: "238",
                          trigger: "click",
                        },
                        on: { hide: _vm.hideMethod },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "stuList clearfix" },
                          [
                            _c(
                              "li",
                              [
                                _c("span", { staticClass: "stu-tit" }, [
                                  _vm._v("全体"),
                                ]),
                                _c("el-switch", {
                                  staticClass: "stu-switch",
                                  on: {
                                    change: function ($event) {
                                      return _vm.switchCheckVideoAll(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.switchVideoAll,
                                    callback: function ($$v) {
                                      _vm.switchVideoAll = $$v
                                    },
                                    expression: "switchVideoAll",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._l(
                              scope.row.lessionStudentInfos,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index },
                                  [
                                    _c("span", { staticClass: "stu-tit" }, [
                                      _vm._v(
                                        _vm._s(item.loginName) +
                                          _vm._s(item.name)
                                      ),
                                    ]),
                                    _c("el-switch", {
                                      staticClass: "stu-switch",
                                      attrs: {
                                        "active-value": "N",
                                        "inactive-value": "Y",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.switchVideo(
                                            scope.row,
                                            item.userId,
                                            item.defunctVideo,
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.defunctVideo,
                                        callback: function ($$v) {
                                          _vm.$set(item, "defunctVideo", $$v)
                                        },
                                        expression: "item.defunctVideo",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        scope.row.video
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  slot: "reference",
                                  size: "small",
                                  type: "primary",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openStudentVideo(scope.$index)
                                  },
                                },
                                slot: "reference",
                              },
                              [_vm._v("题解视频")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-popover",
        {
          ref: "popover4",
          attrs: { placement: "right", width: "400", trigger: "click" },
          model: {
            value: _vm.visible2,
            callback: function ($$v) {
              _vm.visible2 = $$v
            },
            expression: "visible2",
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: {
                width: "100%",
                height: "350px",
                "overflow-y": "auto",
              },
              attrs: {
                data: _vm.studentMessageArray,
                "tooltip-effect": "dark",
              },
              on: { "selection-change": _vm.handleSelection },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { prop: "loginName", label: "学号", width: "120" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "姓名",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "10px" },
              on: { click: _vm.openSomeStuSomeQues },
            },
            [_vm._v("全部开放")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "10px" },
              on: { click: _vm.closeSomeStuSomeQues },
            },
            [_vm._v("全部关闭")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { slot: "reference", type: "info", size: "medium" },
              slot: "reference",
            },
            [_vm._v("批量开放")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }